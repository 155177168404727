import { Injectable, ViewContainerRef } from "@angular/core";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmDialog } from "./confirm-dialog.component";
import { Observable, from } from "rxjs";

@Injectable({ providedIn: 'root' })

export class DialogsService {

  constructor(private ngbModal: NgbModal) { }

  public confirm(title: string, message: string): Observable<boolean> {
    let ngbModalOptions: NgbModalOptions = { size: 'sm', backdrop: 'static', keyboard: false, centered: true };
    const modalRef = this.ngbModal.open(ConfirmDialog, ngbModalOptions);
    modalRef.componentInstance.confirmationBoxTitle = title;
    modalRef.componentInstance.confirmationMessage = message;
    return from(modalRef.result);
  }


}

