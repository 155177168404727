import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: "root" })
export class SimbaAdminService {
  constructor(public httpClient: HttpClient) {}

  getVersionsList() {
    return this.httpClient.post(
      environment.authApiBase + "/simbaDeployment/getVersions",
      {},
      { withCredentials: false }
    );
  }

  getLatestVersion(){
    return this.httpClient.get(environment.authApiBase + "/simbaDeployment/getLatestVersion");
  }
  saveNewVersion(data) {
    return this.httpClient.post(
      environment.authApiBase + "/simbaDeployment/saveNewVersion",
      data,
      { withCredentials: false }
    );
  }

  deleteVersion(id) {
    return this.httpClient.post(
      environment.authApiBase + "/simbaDeployment/deleteVersion",
      { id },
      { withCredentials: false }
    );
  }

  getServiceProviderList(){
    return this.httpClient.get(environment.authApiBase + "/simbaAdmin/getServiceProvidersList");
  }

  getServiceProviderById(id){
    return this.httpClient.post(environment.authApiBase + "/simbaAdmin/getServiceProviderById",{"id":id});
  }

  saveServiceProvider(formData,curdFlag){
    if(curdFlag == "edit"){
      return this.httpClient.post(environment.authApiBase + "/simbaAdmin/updateServiceProvider",formData);
    }else{
      return this.httpClient.post(environment.authApiBase + "/simbaAdmin/saveServiceProvider",formData);
    }
  }
}
